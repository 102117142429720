@import "../../scss/Variables.scss";

.Contact {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 40px;
    grid-row-gap: 20px;
    justify-content: center;

    p {
        margin-top: 0px;
    }

    header {
        h1 {
            color: $primary-body-color;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .Contact__Show__Architecture {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: #4e4e4eb3;
        z-index: 9999999;

        > div {
            width: 100%;
            height: 100%;
            background-image: url("./Architecture.svg");
            background-repeat: no-repeat;
            background-size: 95% 100%;
            background-position: center;
        }

        > i.close {
            display: inline-block;
            position: absolute;
            top: 20px;
            left: 20px;
            height: 50px;
            width: 50px;
            cursor: pointer;

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0px;
                left: 50%;
                height: 50px;
                width: 4px;
                transform: rotate(45deg);
                background-color: $accent-color-one;
            }

            &::before {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0px;
                left: 50%;
                height: 50px;
                width: 4px;
                transform: rotate(-45deg);
                background-color: $accent-color-one;
            }
        }
    }

    > section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 20px;

        .Contact__Form__Links {
            display: grid;
            align-items: end;
            justify-content: end;

            ul {
                margin: 0px;
                margin-bottom: 50px;
                list-style: none;

                li {
                    margin-bottom: 20px;

                    a {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        color: $primary-body-color;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
            }
        }

        div.Contact__Editor {
            background-color: $primary-body-color;

            p {
                color: $primary-bg-color !important;
            }

            #editor-container {
                height: 375px;
                color: $primary-bg-color;
                font-size: 16px;
                font-weight: 400;
            }
        }

        form.Contact__Form {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 20px;

            p {
                color: $primary-body-color;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            input[type="email"] {
                width: 100%;
                height: 50px;
                padding: 10px 8px;

                color: $primary-bg-color;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                outline: 0px !important;
            }

            input[type="submit"] {
                display: flex;
                min-width: 120px;
                height: 50px;
                padding: 10px 8px;

                align-items: center;
                justify-content: center;

                border-radius: 4px;
                border: 1px solid $accent-color-one;
                background-color: $accent-color-one;

                color: $primary-body-color;
            }

            .Contact__Form__Buttons {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

@include tablet() {
    .Contact {
        padding: 0px 20px;
        p {
        }

        header {
            h1 {
                font-size: 32px;
            }
        }

        > section {
            grid-template-columns: 2fr 1fr;

            .Contact__Form__Links {
                ul {
                    li {
                        a {
                        }
                    }
                }
            }

            div.Contact__Editor {
                #editor-container {
                }
            }

            form.Contact__Form {
                p {
                }

                input[type="email"] {
                }

                input[type="submit"] {
                }

                .Contact__Form__Buttons {
                }
            }
        }
    }
}

@include mobile() {
    .Contact {
        padding: 0px 10px;
        p {
        }

        header {
            h1 {
                font-size: 26px;
            }
        }

        .Contact__Show__Architecture {
            > div {
                transform: scale(2) rotate(90deg);
            }
        }

        > section {
            grid-template-columns: 1fr;

            .Contact__Form__Links {
                justify-content: center;
                ul {
                    margin-bottom: 0px;
                    li {
                        a {
                            font-size: 20px;
                        }
                    }
                }
            }

            div.Contact__Editor {
                #editor-container {
                }
            }

            form.Contact__Form {
                p {
                    font-size: 20px;
                }

                input[type="email"] {
                    font-size: 16px;
                }

                input[type="submit"] {
                }

                .Contact__Form__Buttons {
                }
            }
        }
    }
}
