@import "../../scss/Variables.scss";

.Header {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 120px;
    align-items: center;

    div.Header__Menu {
        display: none;
    }

    div.Header__Logo {
        width: 256px;
        height: 60px;
        flex-shrink: 0;
        background-image: url("./Logo.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    nav.Header__Navigation {
        display: flex;
        justify-content: flex-end;

        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            gap: 40px;

            &.side {
                $height: 40px;
                position: fixed;
                padding: 0px 10px;
                transform: rotate(90deg);
                top: 80%;
                right: $height/2 - 2px;
                transform-origin: right;
                background: $primary-bg-color;
                height: 56px;
                display: flex;
                align-items: center;
                z-index: 100;
                width: max-content;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                li {
                    font-size: 24px;
                }
            }

            li {
                color: $primary-body-color;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                a {
                    display: block;
                    color: inherit;
                    width: 100%;
                    height: 100%;
                    text-decoration: none;

                    &::before {
                        content: "{{ ";
                    }

                    &::after {
                        content: " }}";
                    }
                }

                &.active {
                    color: $accent-color-one;

                    a {
                        &::before {
                            content: "";
                        }

                        &::after {
                            content: "";
                        }
                    }
                }
            }
        }
    }
}

@include tablet() {
    .Header {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        background: #111226;
        margin: auto 0px;
        position: fixed;
        width: 100%;
        top: 0px;
        left: 0px;
        padding: 20px 20px;

        overflow: hidden;
        height: 60px;
        z-index: 999;

        transition: 300ms height ease-in-out;

        &.active {
            height: 380px;
            div.Header__Menu {
                background-image: url("./cross.svg");
                background-size: contain;
            }
        }

        div.Header__Menu {
            display: inline-block;
            width: 45px;
            height: 30px;
            z-index: 99;
            grid-column: 1;
            grid-row: 1;
            background-image: url("./Hamburger.svg");
            background-repeat: no-repeat;
            background-size: 100%;
        }

        div.Header__Logo {
            width: 128px;
            height: 30px;
            grid-column: 2;
            grid-row: 1;
            flex-shrink: 0;
            background-image: url("./Logo.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        nav.Header__Navigation {
            display: flex;
            justify-content: flex-start;
            grid-column: 1;
            grid-row: 2;

            ul {
                list-style: none;
                margin: 0px;
                padding: 0px;
                display: flex;
                gap: 40px;

                position: relative;
                transform: none;
                flex-direction: column;
                align-items: flex-start;
                padding: 20px 0px;

                &.side {
                    padding: 0px;
                    overflow: auto;
                    transform: rotate(0deg);
                    position: relative;
                    height: auto;
                    background-color: transparent;
                    align-items: flex-start;
                    justify-content: flex-start;
                    top: 0px;
                    left: 0px;
                    padding: 20px 0px;

                    li {
                        font-size: 24px;
                    }
                }

                li {
                    color: $primary-body-color;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    a {
                        display: block;
                        color: inherit;
                        width: 100%;
                        height: 100%;
                        text-decoration: none;

                        &::before {
                            content: "{{ ";
                        }

                        &::after {
                            content: " }}";
                        }
                    }

                    &.active {
                        color: $accent-color-one;

                        a {
                            &::before {
                                content: "";
                            }

                            &::after {
                                content: "";
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mobile() {
    .Header {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        background: #111226;
        margin: auto 0px;
        position: fixed;
        width: 100%;
        top: 0px;
        left: 0px;
        padding: 20px 10px;

        overflow: hidden;
        height: 60px;
        z-index: 999;

        transition: 300ms height ease-in-out;

        &.active {
            height: 380px;
            div.Header__Menu {
                background-image: url("./cross.svg");
                background-size: contain;
            }
        }

        div.Header__Menu {
            display: inline-block;
            width: 45px;
            height: 30px;
            z-index: 99;
            grid-column: 1;
            grid-row: 1;
            background-image: url("./Hamburger.svg");
            background-repeat: no-repeat;
            background-size: 100%;
        }

        div.Header__Logo {
            width: 128px;
            height: 30px;
            grid-column: 2;
            grid-row: 1;
            flex-shrink: 0;
            background-image: url("./Logo.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        nav.Header__Navigation {
            display: flex;
            justify-content: start;
            grid-column: 1;
            grid-row: 2;

            ul {
                list-style: none;
                margin: 0px;
                padding: 0px;
                display: flex;
                gap: 40px;

                position: relative;
                transform: none;
                flex-direction: column;
                align-items: start;
                padding: 20px 0px;

                &.side {
                    padding: 0px;
                    overflow: auto;
                    transform: rotate(0deg);
                    position: relative;
                    height: auto;
                    background-color: transparent;
                    align-items: start;
                    justify-content: start;
                    top: 0px;
                    left: 0px;
                    padding: 20px 0px;

                    li {
                        font-size: 24px;
                    }
                }

                li {
                    color: $primary-body-color;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    a {
                        display: block;
                        color: inherit;
                        width: 100%;
                        height: 100%;
                        text-decoration: none;

                        &::before {
                            content: "{{ ";
                        }

                        &::after {
                            content: " }}";
                        }
                    }

                    &.active {
                        color: $accent-color-one;

                        a {
                            &::before {
                                content: "";
                            }

                            &::after {
                                content: "";
                            }
                        }
                    }
                }
            }
        }
    }
}
