@import "../../scss/Variables.scss";

.Skills {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 40px;
    grid-row-gap: 20px;
    justify-content: center;

    span {
        &.one {
            color: $accent-color-two;
        }
        &.two {
            color: $accent-color-three;
        }
        &.three {
            color: $accent-color-five;
        }
    }

    p {
        margin: 0px;
    }

    header.Skills__Header {
        h1 {
            color: $primary-body-color;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    > section.Skills__Content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 10px;

        > div {
            display: grid;
            background: #1a1a1a;
            justify-content: center;
            grid-template-rows: auto 1fr;
            padding: 20px;
            border-radius: 25px;

            h3 {
                color: $neutral-one;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                margin-bottom: 10px;
            }

            div {
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                div.skilllist {
                    p {
                        color: $accent-color-four;
                        padding-left: 20px;
                    }
                }
            }

            div.codespace {
                padding-left: 20px;
            }
        }
    }
}

@include tablet() {
    .Skills {
        padding: 0px 20px;
        grid-row-gap: 20px;
        justify-content: center;

        span {
        }

        p {
        }

        header.Skills__Header {
            h1 {
                font-size: 32px;
            }
        }

        > section.Skills__Content {
            grid-template-columns: 1fr;
            grid-row-gap: 20px;

            > div {
                justify-content: start;

                h3 {
                    font-size: 26px;
                }

                div {
                    font-size: 24px;
                    div.skilllist {
                        display: flex;
                        flex-wrap: wrap;

                        p {
                            padding-left: 10px;
                        }
                    }
                }

                div.codespace {
                }
            }
        }
    }
}

@include mobile() {
    .Skills {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px 10px;
        grid-row-gap: 10px;
        justify-content: center;

        span {
        }

        p {
        }

        header.Skills__Header {
            h1 {
                font-size: 26px;
            }
        }

        > section.Skills__Content {
            grid-template-columns: 1fr;
            grid-row-gap: 20px;

            > div {
                justify-content: start;

                h3 {
                    font-size: 21px;
                }

                div {
                    font-size: 16px;
                    div.skilllist {
                        display: flex;
                        flex-wrap: wrap;

                        p {
                            padding-left: 10px;
                        }
                    }
                }

                div.codespace {
                }
            }
        }
    }
}
