@import-normalize; /* bring in normalize.css styles */
@import "./Variables.scss";

body {
  margin: 0;
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  font-size: $body-base-font-size;

  background-color: $primary-bg-color;
  color: $primary-body-color;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: $primary-bg-color;
  border-radius: 10px;
  border: transparent;
}

::-webkit-input-placeholder {
  /* Edge */
  color: $primary-bg-color;
  font-size: 16px;
  font-style: italic;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $primary-bg-color;
  font-size: 16px;
  font-style: italic;
}

::placeholder {
  color: re$primary-bg-color;
  font-size: 16px;
  font-style: italic;
}
