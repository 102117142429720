@import "./Variables.scss";
.App {
    $base-width: $desktop-large;
    $base-padding: 40px;

    display: grid;
    grid-template-columns: auto minmax(auto, $base-width) auto;

    overflow-y: scroll;
    scroll-behavior: smooth;

    * {
        box-sizing: border-box;
    }

    .App__Content {
        display: grid;
        padding: 0px $base-padding;
        grid-template-columns: 1fr;
        grid-row-gap: $base-padding;
    }
}

@include tablet() {
    .App {
        $base-padding: 20px;
        .App__Content {
            margin-top: 100px;
            padding: 0px $base-padding;
            grid-row-gap: $base-padding;
        }
    }
}

@include mobile() {
    .App {
        $base-padding: 10px;
        .App__Content {
            margin-top: 100px;
            padding: 0px $base-padding;
            grid-row-gap: 2 * $base-padding;
        }
    }
}
