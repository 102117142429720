:root {
    --primary-bg-color: #0a0b1a;
    --primary-body-color: #fff;
    --accent-color-one: #ee2c43;
    --accent-color-two: #b14eff;
    --accent-color-three: #ffc700;
    --accent-color-four: #76f53a;
    --accent-color-five: #4e7fff;
    --neutral-one: #7b7b7b;
}

$primary-bg-color: var(--primary-bg-color);
$primary-body-color: var(--primary-body-color);

$accent-color-one: var(--accent-color-one);
$accent-color-one-light: transparentize(
    $color: #ee2c43,
    $amount: 0.5
);
$accent-color-two: var(--accent-color-two);
$accent-color-two-light: transparentize(
    $color: #b14eff,
    $amount: 0.5
);
$accent-color-three: var(--accent-color-three);
$accent-color-three-light: transparentize(
    $color: #ffc700,
    $amount: 0.5
);
$accent-color-four: var(--accent-color-four);
$accent-color-four-light: transparentize(
    $color: #76f53a,
    $amount: 0.5
);
$accent-color-five: var(--accent-color-five);
$accent-color-five-light: transparentize(
    $color: #4e7fff,
    $amount: 0.5
);

$neutral-one: var(--neutral-one);

$body-base-font-size: 16px;

$mobile-small: 320px;
$mobile-medium: 412px;
$mobile-large: 540px;

$tablet-small: 768px;
$tablet-medium: 960px;
$tablet-large: 1280px;

$desktop-extra-large: 1920px;
$desktop-large: 1728px;
$desktop-standard: 1512px;
$desktop-medium: 1440px;
$desktop-small: 1366px;

@mixin mobile() {
    @media only screen and (min-device-width: $mobile-small) and (max-device-width: calc($tablet-small - 1px)) {
        @content;
    }
}

@mixin mobile-small() {
    @media only screen and (min-device-width: $mobile-small) and (max-device-width: calc($mobile-medium - 1px)) {
        @content;
    }
}

@mixin mobile-medium() {
    @media only screen and (min-device-width: $mobile-medium) and (max-device-width: calc($mobile-large - 1px)) {
        @content;
    }
}

@mixin mobile-large() {
    @media only screen and (min-device-width: $mobile-large) and (max-device-width: calc($tablet-small - 1px)) {
        @content;
    }
}

@mixin tablet() {
    @media only screen and (min-device-width: $tablet-small) and (max-device-width: calc($desktop-small - 1px)) {
        @content;
    }
}

@mixin tablet-small() {
    @media only screen and (min-device-width: $tablet-small) and (max-device-width: calc($tablet-medium - 1px)) {
        @content;
    }
}

@mixin tablet-medium() {
    @media only screen and (min-device-width: $tablet-medium) and (max-device-width: calc($tablet-large - 1px)) {
        @content;
    }
}

@mixin tablet-large() {
    @media only screen and (min-device-width: $tablet-large) and (max-device-width: calc($desktop-small - 1px)) {
        @content;
    }
}

@mixin desktop() {
    @media only screen and (min-device-width: $desktop-small) {
        @content;
    }
}

@mixin desktop-small() {
    @media only screen and (min-device-width: $desktop-small) and (max-device-width: calc($desktop-medium - 1px)) {
        @content;
    }
}

@mixin desktop-medium() {
    @media only screen and (min-device-width: $desktop-medium) and (max-device-width: calc($desktop-standard - 1px)) {
        @content;
    }
}

@mixin desktop-standard() {
    @media only screen and (min-device-width: $desktop-standard) and (max-device-width: calc($desktop-large - 1px)) {
        @content;
    }
}

@mixin desktop-large() {
    @media only screen and (min-device-width: $desktop-large) {
        @content;
    }
}
