@import "../../scss/Variables.scss";

.Showcase {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 40px;
    grid-row-gap: 20px;
    justify-content: center;

    i {
        display: inline-block;
        width: 25px;
        height: 25px;

        &.link {
            background-image: url("./external_link.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    header {
        h1 {
            color: $primary-body-color;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    > section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 20px;

        > div {
            padding: 10px 20px;
            cursor: pointer;

            &:nth-child(4n + 1) {
                background-color: $accent-color-two-light;
            }
            &:nth-child(4n + 2) {
                background-color: $accent-color-three-light;
            }
            &:nth-child(4n + 3) {
                background-color: $accent-color-four-light;
            }
            &:nth-child(4n + 4) {
                background-color: $accent-color-five-light;
            }

            > div {
                display: grid;
                grid-template-columns: 1fr auto;

                h3 {
                    color: $primary-body-color;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: $primary-body-color;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@include mobile {
    .Showcase {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px 20px;
        grid-row-gap: 20px;
        justify-content: center;
    
        i {
            display: inline-block;
            width: 25px;
            height: 25px;
    
            &.link {
                background-image: url("./external_link.svg");
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    
        header {
            h1 {
                color: $primary-body-color;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    
        > section {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
            grid-column-gap: 20px;
    
            > div {
                padding: 10px 20px;
                cursor: pointer;
    
                &:nth-child(4n + 1) {
                    background-color: $accent-color-two-light;
                }
                &:nth-child(4n + 2) {
                    background-color: $accent-color-three-light;
                }
                &:nth-child(4n + 3) {
                    background-color: $accent-color-four-light;
                }
                &:nth-child(4n + 4) {
                    background-color: $accent-color-five-light;
                }
    
                > div {
                    display: grid;
                    grid-template-columns: 1fr auto;
    
                    h3 {
                        color: $primary-body-color;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
    
                    p {
                        color: $primary-body-color;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

@include tablet() {
    .Showcase {
        padding: 0px 20px;

        header {
            h1 {
                font-size: 26px;
            }
        }
    }
}
