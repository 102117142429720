@import "../../scss/Variables.scss";

.Timeline {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 40px;
    grid-row-gap: 20px;
    justify-content: center;

    header {
        h1 {
            color: $primary-body-color;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    > section {
        display: flex;
        padding: 100px 0px;
        overflow-x: auto;
        overflow-y: hidden;

        > div {
            position: relative;
            min-width: 360px;
            height: 130px;
            z-index: 50;
            font-size: 14px;
            font-weight: 300;

            i.accent-line {
                position: absolute;
                height: 25px;
                width: 2px;
                background: $accent-color-five;
                top: -25px;
                left: calc(50% - 1px);

                &::after {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background-color: $accent-color-four;
                    border-radius: 50%;
                    position: absolute;
                    top: -15px;
                    left: -4px;
                }
                &::before {
                    content: "";
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border: 2px solid $accent-color-five;
                    border-radius: 50%;
                    position: absolute;
                    top: -22px;
                    left: -11px;
                }
            }

            > div {
                position: absolute;
                width: 100%;
                left: 0;
                font-size: 20px;
                font-weight: 600;
                color: $accent-color-five;
                text-align: center;
            }

            > p {
                padding: 0px 10px;
                position: absolute;
                overflow: hidden;
                line-height: 28px;
                height: 234px;
                font-size: 20px;
            }

            &:nth-child(odd) {
                border-bottom: 2px solid $accent-color-five;

                > i {
                    top: auto;
                    bottom: -25px;
                    transform: rotate(180deg);
                }

                > div {
                    bottom: -80px;
                }

                > p {
                    display: flex;
                    bottom: 0px;
                    align-items: flex-end;
                }
            }

            &:nth-child(even) {
                border-top: 2px solid $accent-color-five;
                border-left: 2px solid $accent-color-five;
                border-right: 2px solid $accent-color-five;

                > div {
                    top: -80px;
                }

                > p {
                    top: 0px;
                }
            }
        }
    }
}

@include tablet() {
    .Timeline {
        padding: 0px 20px;

        header {
            h1 {
                font-size: 32px;
            }
        }

        > section {
            > div {
                > p {
                    line-height: 24px;
                    font-size: 18px;
                }
            }
        }
    }
}

@include mobile() {
    .Timeline {
        padding: 0px 10px;
        header {
            h1 {
                font-size: 26px;
            }
        }

        > section {
            > div {
                min-width: 250px;
                > p {
                    line-height: 22px;
                    font-size: 16px;
                }
            }
        }
    }
}
