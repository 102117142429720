@import "../../../scss/Variables.scss";

.Loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(26 26 26 / 50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;

    input[type="range"] {
        height: 38px;
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
    }
    input[type="range"]:focus {
        outline: none;
    }
    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 1px 1px 1px $neutral-one;
        background: $accent-color-four;
        border-radius: 5px;
        border: 1px solid $neutral-one;
    }
    input[type="range"]::-webkit-slider-thumb {
        box-shadow: 1px 1px 1px $neutral-one;
        border: 1px solid $neutral-one;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        background: $accent-color-four;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6px;
    }
    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: $accent-color-four;
    }
    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 1px 1px 1px $neutral-one;
        background: $accent-color-four;
        border-radius: 5px;
        border: 1px solid $neutral-one;
    }
    input[type="range"]::-moz-range-thumb {
        box-shadow: 1px 1px 1px $neutral-one;
        border: 1px solid $neutral-one;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        background: $accent-color-four;
        cursor: pointer;
    }
    input[type="range"]::-ms-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    input[type="range"]::-ms-fill-lower {
        background: $accent-color-four;
        border: 1px solid $neutral-one;
        border-radius: 10px;
        box-shadow: 1px 1px 1px $neutral-one;
    }
    input[type="range"]::-ms-fill-upper {
        background: $accent-color-four;
        border: 1px solid $neutral-one;
        border-radius: 10px;
        box-shadow: 1px 1px 1px $neutral-one;
    }
    input[type="range"]::-ms-thumb {
        margin-top: 1px;
        box-shadow: 1px 1px 1px $neutral-one;
        border: 1px solid $neutral-one;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        background: $accent-color-four;
        cursor: pointer;
    }
    input[type="range"]:focus::-ms-fill-lower {
        background: $accent-color-four;
    }
    input[type="range"]:focus::-ms-fill-upper {
        background: $accent-color-four;
    }
}
