@import "../../../scss/Variables.scss";

.Accordian {
    p {
        margin: 0px;
    }

    i {
        display: inline-block;
        width: 24px;
        height: 24px;

        &.location {
            background-image: url("./geo_location.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }

        &.link {
            background-image: url("./external_link.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    header.Accordian__Header {
        border-radius: 8px;
        background-color: #dc6916;
        padding: 10px 0px;

        i.minus {
            position: relative;
            display: inline-block;
            width: 24px;
            height: 25px;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 4px;
                border-radius: 2px;
                top: calc(50% - 2px);
                left: 0px;
                background-color: white;
            }
        }

        i.plus {
            position: relative;
            display: inline-block;
            width: 24px;
            height: 25px;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 4px;
                border-radius: 2px;
                top: calc(50% - 2px);
                left: 0px;
                background-color: white;
            }

            &::after {
                content: "";
                position: absolute;
                width: 4px;
                height: 100%;
                border-radius: 2px;
                left: calc(50% - 2px);
                top: 0px;
                background-color: white;
            }
        }

        > div {
            display: flex;
            padding: 0px 20px;
            align-items: center;

            &:nth-child(1) {
                h1 {
                    color: #fff;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    flex: 1;
                }
            }
            &:nth-child(2) {
                h2 {
                    color: #fff;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    flex: 1;
                }

                > div {
                    color: #fff;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }

    div.Accordian__Body {
        height: 0px;
        padding: 0px;
        overflow: hidden;
        transition: all 300ms ease-in-out;
        background-color: #3a1d08;
        color: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        div.Accordian__Body_Title {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            margin-bottom: 10px;
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            a {
                color: inherit;
                text-decoration: none;
                cursor: pointer;
            }

            > div {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        div.Accordian__Body_Content {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &.open {
        div.Accordian__Body {
            height: auto;
            padding: 10px 20px;
            overflow: none;
        }

        header.Accordian__Header {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}

@include tablet() {
    .Accordian {
        p {
        }

        i {

            &.location {
            }

            &.link {
            }
        }

        header.Accordian__Header {
            padding: 10px 0px;

            i.minus {

                &::before {
                }
            }

            i.plus {

                &::before {
                }

                &::after {
                }
            }

            > div {

                &:nth-child(1) {
                    h1 {
                        font-size: 26px;
                    }
                }
                &:nth-child(2) {
                    h2 {
                        font-size: 20px;
                    }

                    > div {
                        font-size: 20px;
                    }
                }
            }
        }

        div.Accordian__Body {

            div.Accordian__Body_Title {
                font-size: 20px;

                a {
                }

                > div {
                }
            }

            div.Accordian__Body_Content {
                font-size: 16px;
            }
        }

        &.open {
            div.Accordian__Body {
                padding: 10px 20px;
            }

            header.Accordian__Header {
            }
        }
    }
}

@include mobile() {
    .Accordian {
        p {
        }

        i {

            &.location {
            }

            &.link {
            }
        }

        header.Accordian__Header {
            
            i.minus {
                width: 16px;
                height: 16px;
                &::before {
                }
            }

            i.plus {
                width: 16px;
                height: 16px;
                &::before {
                }

                &::after {
                }
            }

            > div {
                padding: 0px 10px;
                &:nth-child(1) {
                    h1 {
                        font-size: 14px;
                    }
                }
                &:nth-child(2) {
                    display: flex;
                    flex-wrap: wrap;
                    h2 {
                        font-size: 14px;
                        font-weight: 400;
                    }

                    > div {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }

        div.Accordian__Body {

            div.Accordian__Body_Title {
                font-size: 14px;
                font-weight: 400;
                a {
                }

                > div {
                }
            }

            div.Accordian__Body_Content {
                font-size: 12px;
                font-weight: 300;
            }
        }

        &.open {
            div.Accordian__Body {
                padding: 10px 10px;
            }

            header.Accordian__Header {
            }
        }
    }
}
