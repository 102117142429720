@import "../../scss/Variables.scss";

.Career {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 40px;
    grid-row-gap: 20px;
    justify-content: center;

    > header {
        h1 {
            color: $primary-body-color;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    > section {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    }
}

@include tablet() {
    .Career {
        padding: 0px 20px;

        > header {
            h1 {
                font-size: 32px;
            }
        }
    }
}

@include mobile() {
    .Career {
        padding: 0px 10px;

        > header {
            h1 {
                font-size: 26px;
            }
        }
    }
}
