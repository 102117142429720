@import "../../scss/Variables.scss";

.Footer {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 40px;
    justify-content: center;

    span {
        &:nth-child(1) {
            color: $accent-color-two;
        }
        &:nth-child(2) {
            color: $accent-color-five;
        }
        &:nth-child(3) {
            color: $accent-color-three;
        }
    }

    > div {
        display: flex;

        p {
            color: $primary-body-color;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:first-child {
                flex: 1;
            }

            &.Footer__Copyright {
                color: $accent-color-four;
            }
        }
    }
}

@include tablet() {
    .Footer {
        padding: 0px 20px;

        > div {
            p {
                font-size: 14px;
            }
        }
    }
}

@include mobile() {
    .Footer {
        padding: 0px 10px;

        > div {
            flex-direction: column;
            align-items: center;

            p {
                font-size: 12px;
            }
        }
    }
}
