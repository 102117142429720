@import "../../../scss/Variables.scss";

.Carousal {
    overflow-x: hidden;

    i {
        display: inline-flex;
        width: 34px;
        height: 24px;
        align-items: center;
        justify-content: center;

        &.left {
            background-image: url("./arrow_right.svg");
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(180deg);
        }

        &.right {
            background-image: url("./arrow_right.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .codespacing {
        display: inline-block;
        padding-left: 20px;
    }

    span.one {
        color: #ee2c43;
    }

    .Carousal__Content {
        display: flex;
        overflow: hidden;
    }

    .Carousal__Buttons {
        display: flex;
        width: 100%;
        margin: auto;
        margin-bottom: 20px;

        button {
            width: 95px;
            height: 50px;
            background-color: #ee2c43;
            outline: 0px;
            border: #ee2c43;
        }

        div {
            flex: 1;
        }
    }

    .Carousal__Card {
        position: relative;
        min-width: calc(100% / 3);
        min-height: 350px;
        background-color: #17355c;
        border-radius: 20px 0px;
        padding: 20px;
        transform: scale(0.9);
        opacity: 0.5;
        filter: opacity(0.5);
        margin-bottom: 15px;

        transition: all 300ms linear;
        translate: -100%;

        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        h4 {
            font-weight: 500;
        }

        &:nth-child(3) {
            transform: scale(1);
            opacity: 1;
            filter: opacity(1);
        }

        &.reset {
            translate: 0%;
        }

        &.left {
            translate: 0%;

            &:nth-child(2) {
                transform: scale(1);
                opacity: 1;
                filter: opacity(1);
            }

            &:nth-child(3) {
                transform: scale(0.9);
                opacity: 0.5;
                filter: opacity(0.5);
            }
        }

        &.right {
            translate: -100%;

            &:nth-child(3) {
                transform: scale(1);
                opacity: 1;
                filter: opacity(1);
            }

            &:nth-child(2) {
                transform: scale(0.9);
                opacity: 0.5;
                filter: opacity(0.5);
            }
        }

        .Carousal_Tags {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            bottom: -15px;
            left: 0px;
            gap: 10px;
            padding-left: 10px;

            > div {
                background: #ffb84e;
                padding: 5px 10px;
                border-radius: 30px;

                color: #1a1a1a;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}

@include desktop-large {
    .Carousal__Content {
        margin: auto -420px;
    }
}

@include desktop-standard {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
            margin-right: -420px;
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: calc(100% / 2);
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include desktop-medium {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
            margin-right: -420px;
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: calc(100% / 2);
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include desktop-small {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
            margin-right: -420px;
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: calc(100% / 2);
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include tablet-large {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
            margin-right: -420px;
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: calc(100% / 2);
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include tablet-medium {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: 100%;
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include tablet-small {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: 100%;
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include mobile-large() {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: 100%;
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include mobile-medium() {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: 100%;
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include mobile-small() {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
        }

        .Carousal__Buttons {
            button {
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: 100%;
            translate: -200%;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                > div {
                }
            }
        }
    }
}

@include mobile() {
    .Carousal {
        i {
            &.left {
            }

            &.right {
            }
        }

        .codespacing {
        }

        span.one {
        }

        .Carousal__Content {
        }

        .Carousal__Buttons {
            button {
                width: 45px;
                height: 40px;
            }

            div {
            }
        }

        .Carousal__Card {
            min-width: 100%;
            translate: -200%;
            position: relative;
            min-height: 640px;
            min-width: calc(100% - 15px);
            padding: 15px;
            font-size: 14px;

            h4 {
            }

            &:nth-child(3) {
            }

            &.reset {
                translate: -100%;
            }

            &.left {
                translate: -100%;

                &:nth-child(2) {
                }

                &:nth-child(3) {
                }
            }

            &.right {
                translate: -200%;

                &:nth-child(3) {
                }

                &:nth-child(2) {
                }
            }

            .Carousal_Tags {
                position: absolute;
                top: 0px;
                left: 100%;
                height: 16px;
                flex-wrap: nowrap;
                width: max-content;
                transform: rotate(90deg);
                transform-origin: left;

                > div {
                    font-size: 10px;
                    padding: 0px 4px;
                }
            }
        }
    }
}
