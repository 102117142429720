@import "../../scss/Variables.scss";

.Hero {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 40px;
    grid-row-gap: 16px;
    justify-content: center;

    .Hero__Info {
        display: grid;
        grid-template-columns: 1fr auto;

        > div {
            p {
                margin: 0;
                padding: 0;

                color: $primary-body-color;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                &:nth-child(1) {
                    span {
                        display: inline-block;
                        color: $accent-color-one;
                        font-size: 64px;
                    }
                }

                &:nth-child(2) {
                    span {
                        display: inline-block;
                        color: $accent-color-two;
                        font-size: 48px;
                        line-height: 20px;

                        &:nth-child(2) {
                            color: $accent-color-three;
                        }
                    }
                }

                &:nth-child(3) {
                    span {
                        display: inline-block;
                        color: $accent-color-four;
                        font-size: 32px;
                    }
                }
            }

            &.Hero__Info__Accent {
                width: 24px;
                height: 100%;

                background-image: url("./scroll_icon.svg");
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .Hero__Bio {
        p {
            color: $primary-body-color;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .Hero__Resume {
        font-size: 16px;
        font-weight: 500;
        a {
            text-decoration: underline;
            color: $accent-color-five !important;
        }
    }
}

@include tablet() {
    .Hero {
        padding: 0px 20px;
    }
}

@include mobile() {
    .Hero {
        padding: 0px 10px;
    }
}

@include tablet-medium() {
    .Hero {
        .Hero__Info {
            > div {
                p {
                    font-size: 24px;

                    &:nth-child(1) {
                        span {
                            font-size: 54px;
                        }
                    }

                    &:nth-child(2) {
                        span {
                            font-size: 36px;
                            line-height: 20px;

                            &:nth-child(2) {
                            }
                        }
                    }

                    &:nth-child(3) {
                        span {
                            font-size: 24px;
                        }
                    }
                }

                &.Hero__Info__Accent {
                }
            }
        }

        .Hero__Bio {
            p {
                font-size: 24px;
            }
        }
    }
}

@include tablet-small() {
    .Hero {
        .Hero__Info {
            > div {
                p {
                    font-size: 20px;

                    &:nth-child(1) {
                        span {
                            font-size: 38px;
                        }
                    }

                    &:nth-child(2) {
                        span {
                            font-size: 26px;
                            line-height: 20px;

                            &:nth-child(2) {
                            }
                        }
                    }

                    &:nth-child(3) {
                        span {
                            font-size: 22px;
                        }
                    }
                }

                &.Hero__Info__Accent {
                }
            }
        }

        .Hero__Bio {
            p {
                font-size: 20px;
            }
        }
    }
}

@include mobile-large() {
    .Hero {
        .Hero__Info {
            > div {
                p {
                    font-size: 18px;

                    &:nth-child(1) {
                        span {
                            font-size: 32px;
                        }
                    }

                    &:nth-child(2) {
                        span {
                            font-size: 20px;
                            line-height: 24px;

                            &:nth-child(2) {
                            }
                        }
                    }

                    &:nth-child(3) {
                        span {
                            font-size: 20px;
                        }
                    }
                }

                &.Hero__Info__Accent {
                }
            }
        }

        .Hero__Bio {
            p {
                font-size: 18px;
            }
        }
    }
}

@include mobile-medium() {
    .Hero {
        .Hero__Info {
            > div {
                p {
                    font-size: 16px;

                    &:nth-child(1) {
                        span {
                            font-size: 26px;
                        }
                    }

                    &:nth-child(2) {
                        span {
                            font-size: 26px;
                            line-height: 26px;

                            &:nth-child(2) {
                            }
                        }
                    }

                    &:nth-child(3) {
                        span {
                            font-size: 20px;
                        }
                    }
                }

                &.Hero__Info__Accent {
                }
            }
        }

        .Hero__Bio {
            p {
                font-size: 16px;
            }
        }
    }
}

@include mobile-small() {
    .Hero {
        .Hero__Info {
            > div {
                p {
                    font-size: 14px;

                    &:nth-child(1) {
                        span {
                            font-size: 18px;
                        }
                    }

                    &:nth-child(2) {
                        span {
                            font-size: 18px;
                            line-height: 18px;

                            &:nth-child(2) {
                            }
                        }
                    }

                    &:nth-child(3) {
                        span {
                            font-size: 16px;
                        }
                    }
                }

                &.Hero__Info__Accent {
                }
            }
        }

        .Hero__Bio {
            p {
                font-size: 14px;
            }
        }
    }
}
